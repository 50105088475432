import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";

const TableContainer = styled.table`
  width: 100%;
  border: 1px solid rgba(30, 30, 30, 1);
  border-radius: 3px;
  border-spacing: 0;
`;

const SectionHeader = styled.th`
  font-size: 1.3rem;
  background-color: rgba(80, 80, 80, 1);
  color: #ffffff;
  font-weight: bold;
  font-size: 13px;
  border: 1px solid rgba(80, 80, 80, 1);
`;

const HeaderRow = styled.tr`
  background: rgba(30, 30, 30, 1);
  border: none;
  color: #fff;
`;

const Row = styled.tr`
  border: none;
  padding: 2rem 0;
  background-color: rgba(245, 245, 245, 1);
`;

const Col = styled.td`
  text-align: ${(props) => props.textAlign || "left"};
  font-size: 1rem;
  line-height: 1.4rem;
  padding: 0.75rem 0.75rem;
  color: rgba(80, 80, 80, 1);
  border: none;
`;

const TBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #ffffff;
  }
`;

export const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <TableContainer cellspacing="0" cellpadding="0" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <HeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <SectionHeader {...column.getHeaderProps()}>{column.render("Header")}</SectionHeader>
            ))}
          </HeaderRow>
        ))}
      </thead>
      <TBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Row {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <Col {...cell.column} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </TBody>
    </TableContainer>
  );
};
