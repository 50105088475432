import React from "react";
import { Doughnut } from "react-chartjs-2";

const middleText = {
  id: "middleText",
  beforeDraw: function (chart, _, { text }) {
    const width = chart.width;
    const height = chart.height;
    const ctx = chart.ctx;

    ctx.restore();
    const fontSize = (height / 140).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";

    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

export const OverallScore = ({ finalPerformance }) => {
  const options = {
    events: [],
    plugins: { legend: { display: false }, middleText: { text: `${finalPerformance.toFixed(0)}%` } },
  };
  const data = {
    labels: ["Final Score"],
    datasets: [
      {
        label: "Final Score",
        data: [finalPerformance, 100 - finalPerformance],
        backgroundColor: ["rgba(75, 210, 130, 0.7)", "rgba(160, 160, 160, 0.2)"],
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut plugins={[middleText]} data={data} options={options} />;
};
