import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../components/Layout";
import { Table } from "../../components/Table";
import { OverallScore } from "../../components/Report/OverallScore";
import { SubSectionComparison } from "../../components/Report/SubSectionComparison";
import styled from "styled-components";
import { SectionComparison } from "../../components/Report/SectionComparison";
import { DateTime } from "luxon";
import { up, down } from "styled-breakpoints";
// import { PayGapComparison } from "../../components/Report/PayGapComparison";

const Background = styled.div`
  background: #f5f5f5;
  width: 100%;
  height: 100%;
`;

const ReportContainer = styled.div`
  position: relative;
  margin: 1rem auto;
  width: 80vw;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  padding: 3rem 3rem;
`;

const ReportRow = styled.div`
  display: flex;
  margin-bottom: 3rem;

  ${up("lg")} {
    flex-direction: row;
  }

  ${down("lg")} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  font-size: 4.25rem;
  line-height: 4rem;
  font-weight: 700;
  width: 40vw;
  margin-bottom: 2rem;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  flex: 1;
`;

const OverallScoreContainer = styled.div`
  width: 20vw;

  ${down("lg")} {
    width: 50vw;
  }
`;

const FirmInformation = styled.div``;

const FirmInformationList = styled.ul`
  padding: 0;
  list-style-type: none;
`;
const FirmInformationListItem = styled.li``;

const ReportSectionTitle = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;

  ${down("lg")} {
    font-size: 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${up("lg")} {
    flex-direction: row;
  }

  ${down("lg")} {
    flex-direction: column;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const GraphCol = styled(Col)`
  max-width: 450px;
  margin-left: 2rem;
  min-height: 350px;
`;

// const PayGapComparisonContainer = styled.div`
//   height: 300px;
// `;

const loadData = async (reference) => {
  const readRequest = axios.get("/.netlify/functions/read", {
    params: { reference },
  });

  const compareRequest = axios.get("/.netlify/functions/compare", {
    params: { reference },
  });

  const [readResponse, compareResponse] = await Promise.all([readRequest, compareRequest]);

  return {
    report: readResponse.data,
    comparison: compareResponse.data,
  };
};

const ReportPage = ({ reference }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    const report = async () => {
      try {
        const response = await loadData(reference);
        setData(response);
      } catch (error) {
        alert("An error has occurred, please contact us.");
      } finally {
        setLoading(false);
      }
    };
    report();
  }, [reference]);

  const resultColumns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "subSection",
        width: "20%",
      },
      {
        Header: "Performance",
        accessor: "performance",
        textAlign: "center",
        width: "15%",
        Cell: ({ cell: { value } }) =>
          Intl.NumberFormat("en-US", { minimumFractionDigits: 2, style: "percent" }).format(value / 100),
      },
      {
        Header: "Result",
        accessor: "result",
      },
    ],
    []
  );

  // TODO: re-enable
  // const recommendationColumns = React.useMemo(
  //   () => [
  //     {
  //       Header: "Rank",
  //       accessor: "rank",
  //       textAlign: "center",
  //       width: "5%",
  //     },
  //     {
  //       Header: "Type",
  //       accessor: "type",
  //       textAlign: "center",
  //       width: "10%",
  //     },
  //     {
  //       Header: "Recommendation",
  //       accessor: "recommendation",
  //       textAlign: "center",
  //       width: "15%",
  //     },
  //     {
  //       Header: "Explanation",
  //       accessor: "explanation",
  //     },
  //     {
  //       Header: "Examples/References",
  //       accessor: "examplesAndReferences",
  //     },
  //   ],
  //   []
  // );

  if (loading) {
    return (
      <Layout>
        <Background>
          <ReportContainer>
            <LogoContainer>Gender Equality Benchmark</LogoContainer>
            <ReportSectionTitle>Loading</ReportSectionTitle>
          </ReportContainer>
        </Background>
      </Layout>
    );
  }
  const tables = [[data.report.finalScore], data.report.sectionScores, data.report.subSectionScores];

  const createdAt =
    data?.report?.customerInformation?.createdAt && DateTime.fromISO(data.report.customerInformation.createdAt);

  return (
    <Layout>
      <Background>
        <ReportContainer>
          <ReportRow>
            <LeftSide>
              <LogoContainer>Gender Equality Benchmark</LogoContainer>
              <FirmInformation>
                <FirmInformationList>
                  <FirmInformationListItem>
                    <strong>Firm Name: </strong>
                    {data?.report?.customerInformation?.companyName}
                  </FirmInformationListItem>
                  <FirmInformationListItem>
                    <strong>Date of Questionnaire: </strong>
                    {createdAt?.toLocaleString(DateTime.DATE_FULL)}
                  </FirmInformationListItem>
                </FirmInformationList>
              </FirmInformation>
            </LeftSide>
            <Spacer />
            <OverallScoreContainer>
              <OverallScore finalPerformance={data.report.finalScore.performance} />
            </OverallScoreContainer>
          </ReportRow>
          <ReportSectionTitle>General Scorecard</ReportSectionTitle>
          {tables?.map((table, index) => (
            <ReportRow key={index}>
              <Table columns={resultColumns} data={table} />
            </ReportRow>
          ))}
          <ReportSectionTitle>Gender Benchmark</ReportSectionTitle>
          <Row>
            <GraphCol>
              <SectionComparison
                sectionScores={data.report.sectionScores}
                peerSectionScores={data.comparison.peerSectionScores}
                globalSectionScores={data.comparison.globalSectionScores}
              />
            </GraphCol>
            <GraphCol>
              <SubSectionComparison
                subSectionScores={data.report.subSectionScores}
                peerSubSectionScores={data.comparison.peerSubSectionScores}
                globalSubSectionScores={data.comparison.globalSubSectionScores}
              />
            </GraphCol>
          </Row>
          {/* TODO: re-enable */}
          {/* <PayGapComparisonContainer>
            <PayGapComparison
              performance={
                data.report.subSectionScores.find((x) => x.subSection === "Gender Pay-Gap")?.performance?.toFixed(0) ??
                "0"
              }
              ukOverallPerformance={data.comparison.payGap.ukOverallPerformance.toFixed(0)}
              ukFinancialServicesPerformance={data.comparison.payGap.ukFinancialServicesPerformance.toFixed(0)}
              peerPerformance={data.comparison.payGap.peerPerformance.toFixed(0)}
            />
          </PayGapComparisonContainer> */}
          {/* TODO: re-enable */}
          {/* <ReportSectionTitle>Recommendations</ReportSectionTitle>
          <ReportRow>
            <Table columns={recommendationColumns} data={data.report.recommentations} />
          </ReportRow> */}
        </ReportContainer>
      </Background>
    </Layout>
  );
};

export default ReportPage;
