import React from "react";
import { Radar } from "react-chartjs-2";

const prepareData = (section) => section.performance.toFixed(0);

export const SubSectionComparison = ({ subSectionScores, peerSubSectionScores, globalSubSectionScores }) => {
  const data = {
    labels: subSectionScores.map((score) => score.subSection),
    datasets: [
      {
        label: "Scores",
        data: subSectionScores.map(prepareData),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
      },
      // TODO: re-enable
      // {
      //   label: "Peer Scores",
      //   data: peerSubSectionScores.map(prepareData),
      //   backgroundColor: "rgba(54, 162, 235, 0.2)",
      // },
      // {
      //   label: "Global Scores",
      //   data: globalSubSectionScores.map(prepareData),
      //   backgroundColor: "rgba(75, 192, 192, 0.2)",
      // },
    ],
  };

  const options = {
    scale: {
      ticks: { beginAtZero: true },
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Score by Subsections",
      },
    },
  };
  return <Radar data={data} options={options} />;
};
