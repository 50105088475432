import React from "react";
import { Bar } from "react-chartjs-2";

const prepareData = (section) => ({ x: section.subSection, y: section.performance.toFixed(0) });

export const SectionComparison = ({ sectionScores, peerSectionScores, globalSectionScores }) => {
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Score by Sections",
      },
    },
  };
  const data = {
    datasets: [
      {
        label: "Scores",
        data: sectionScores.map(prepareData),
        backgroundColor: "rgb(255, 99, 132)",
      },
      // TODO: re-enable
      // {
      //   label: "Peer Scores",
      //   data: peerSectionScores.map(prepareData),
      //   backgroundColor: "rgb(54, 162, 235)",
      // },
      // {
      //   label: "Global Scores",
      //   data: globalSectionScores.map(prepareData),
      //   backgroundColor: "rgb(75, 192, 192)",
      // },
    ],
  };
  return <Bar data={data} options={options} />;
};
